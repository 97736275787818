import {XhrService} from "../../../default/global/ts/xhr.service";
import {addLoadingClass, removeLoadingClass} from "../../../default/global/js/utilities/updateClasses";
import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import {clearRefinements, configure, hits} from "instantsearch.js/es/widgets";
import {
    buildItemlistViewSwitch,
    convertBackValue,
    convertValue,
    escapeFilterValue,
    getBlockedCountryFilter,
    getCurrentAlgoliaTemplate,
    getCustomerCountryCode,
    getCustomHitsPerPage,
    getCustomPagination,
    hideListViewSwitch,
    showListViewSwitch,
    showSearchView,
    sortNumerically,
    updateHitValues
} from "./algoliaFunctions";
import {connectRefinementList} from "instantsearch.js/es/connectors";
import {slideDown, slideUp} from "../../../default/global/js/utilities/slide";

let clickedFirst = false;
let firstLoad = true;
let tabEventLoaded = false;
let shiftPressed = false;
let initArrowPressed = false;

const arrowKeysHandler = function (e) {
    switch (e.code) {
        case "ArrowUp":
        case "ArrowDown":
            e.preventDefault();
            break;
        default:
            break; // do not block other keys
    }
};

function handleTabPress() {
    if(!tabEventLoaded){
        document.addEventListener('keydown', function (e) {
            tabEventLoaded = true;
            if (e.key === "Shift") {
                //shiftPressed = true;
            }
            if (e.key === "Tab" || e.keyCode === 9 || e.which === 9) {
                const headerExpertSearch = document.querySelector(".headerExpertSearch");
                if (headerExpertSearch) {
                    if (document.activeElement.classList.contains("ais-SearchBox-input")) {
                        let currWrapper = document.activeElement.closest(".filterWrapper");
                        if (currWrapper) {
                            closeFilter(currWrapper);
                            let nextWrapper = getNextVisibleFilterWrapper(currWrapper);

                            if (shiftPressed) {
                                nextWrapper = getPrevVisibleFilterWrapper(currWrapper);
                            }
                            e.stopPropagation();
                            e.preventDefault();
                            openFilter(nextWrapper);
                        }
                    }
                }
            }
        });
        document.addEventListener('keyup', function (e) {
            if (e.key === "Shift") {
                //shiftPressed = false;
            }
        });
    }
}

function handleArrowKeys(e) {
    let filterWrapper = e.target.closest('.filterWrapper');
    if (filterWrapper) {
        let filterListElements = filterWrapper.querySelectorAll('.ais-RefinementList-item');
        if (filterListElements) {
            if (e.key === "ArrowDown") {
                activateFilterListElement(filterListElements, 1);
            }
            if (e.key === "ArrowUp") {
                activateFilterListElement(filterListElements, -1);
            }
        }
    }
}

function handleArrowKeysPress() {
    if (!initArrowPressed) {
        for (let filterWrapper of document.querySelectorAll('.headerExpertSearch .filterWrapper')) {
            filterWrapper.addEventListener("keydown", function (e) {
                handleArrowKeys(e);
            });
        }
        initArrowPressed = true;
    }
}

function handleClickOutside(options) {
    document.addEventListener("click", function (e) {
        if (!e.target.closest(".filterWrapper")) {
            closeAllFilters(options);
        }
    });
}

function handleEnterPressFilterCheckbox() {
    document.addEventListener('keyup', function (e) {
        if (e.key === "Enter" && e.target.classList.contains("ais-RefinementList-checkbox")) {
            e.target.click();
        }
    });
}

function handleClickSubmitButton(options){
    const submitButton = document.getElementById(options.expertSearchBarSubmitButtonId);
    submitButton.addEventListener("click",function (e){
        if (checkActiveFilter(options)) {
            submitButton.href = getNewSearchHref();
            //submitButton.classList.remove("button--inactive");
            hideErrorMessage(options);
        } else {
            //submitButton.classList.add("button--inactive");
            showErrorMessage(options);
            e.preventDefault();
        }
    });
}

function getTemplateRefinementListItem(item,url,type = 0,dataType = 0){
    let html = ``;
    let label = item.label;
    //if data type is not text
    if(dataType !== 4){
        label = convertValue(label);
    }
    switch (type){
        case 4:
            let newValue = escapeFilterValue(item.value);
            html = `<li class="ais-RefinementList-item${item.isRefined ? ' ais-RefinementList-item--selected' : ''}">
                  <div><label class="ais-RefinementList-label specialcheckbox">
                      <input type="checkbox" class="ais-RefinementList-checkbox" value='${newValue}' data-url="${url}">
                      <i></i>
                      <span class="ais-RefinementList-labelTextWrapper">
                          <span class="ais-RefinementList-labelText">${label}</span>
                          <span class="ais-RefinementList-count">(${item.count})</span>
                      </span>
                  </label></div>
                </li>
              `;
            break;
        default:
            html = `<li class="ais-RefinementList-item${item.isRefined ? ' ais-RefinementList-item--selected' : ''}">
                  <a
                    href="${url}"
                    data-value='${item.value}'
                    class="${item.isRefined ? 'active' : ''}"
                  >
                  <span class="ais-RefinementList-labelTextWrapper">
                      <span class="ais-RefinementList-labelText">${label}</span>
                      <span class="ais-RefinementList-count">(${item.count})</span>
                  </span>
                  <i class="icon icon-close ais-RefinementList-deleteRefinement"></i>
                  </a>
                </li>
              `;
            break;
    }

    return html;

}

function getNextVisibleFilterWrapper(currWrapper) {
    let currentIndex = 0;
    let count = 0;
    let filterWrappers = document.querySelectorAll('.filterWrapper:not(.disabled');
    if (filterWrappers) {
        for (let wrapper of filterWrappers) {
            if (currWrapper === wrapper) {
                currentIndex = count;
                break;
            }
            count++;
        }
        let nextWrapper = filterWrappers[currentIndex + 1];
        if (!nextWrapper) {
            return false;
        }
        return nextWrapper;
    }

    return false;
}

function getPrevVisibleFilterWrapper(currWrapper) {
    let currentIndex = 0;
    let count = 0;
    let filterWrappers = document.querySelectorAll('.filterWrapper:not(.disabled');
    if (filterWrappers) {
        for (let wrapper of filterWrappers) {
            if (currWrapper === wrapper) {
                currentIndex = count;
                break;
            }
            count++;
        }
        let nextWrapper = filterWrappers[currentIndex - 1];
        if (!nextWrapper) {
            nextWrapper = filterWrappers[filterWrappers.length - 1];
        }
        return nextWrapper;
    }

    return false;

}

function deactivateFilterListElement(filterWrapper) {
    for (let filterListElement of filterWrapper.querySelectorAll('.ais-RefinementList-item')) {
        filterListElement.classList.remove("is-active");
    }
}

function activateFilterListElement(filterListElements, direction = 1) {
    //direction -1 = up, 1 = down
    let currentActive = 0;
    let nextFilterListElement = false;
    for (filterListElement of filterListElements) {
        if (filterListElement.classList.contains("is-active")) {
            filterListElement.classList.remove("is-active");
            nextFilterListElement = filterListElements[currentActive + direction];
            break;
        }
        currentActive++;
    }

    if (!nextFilterListElement) {
        nextFilterListElement = filterListElements[0];
    }

    nextFilterListElement.classList.add("is-active");
    let filter = nextFilterListElement.querySelector("a, .specialcheckbox input");

    if (filter) {
        filter.focus();
    }

    return nextFilterListElement;
}

function checkActiveFilter(options) {
    if (options.expertSearchBar.querySelector('.ais-ClearRefinements-button--disabled')) {
        return false;
    }
    return true;
}

function buildExpertSearchFilter(renderOptions){
    const {
        widgetParams
    } = renderOptions;

    if(!widgetParams.container.querySelector('.ais-RefinementList')){

        const input = document.createElement('input');
        const ul = document.createElement('ul');

        let htmlWrapper = '<div class="ais-RefinementList"><div class="ais-RefinementList-searchBox"><form action role="search" class="ais-SearchBox-form" novalidate><button class="ais-SearchBox-reset" type="reset"><svg class="ais-SearchBox-resetIcon" viewBox="0 0 20 20" width="10" height="10" aria-hidden="true"><path d="M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20 1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17 7.17-.944.943L0 18.114l.943-.943L8.113 10z"></path></svg></button></form></div></div>';
        let wrapper = new DOMParser().parseFromString(htmlWrapper, 'text/html');

        let refinementWrapper = wrapper.querySelector('.ais-RefinementList');
        if (refinementWrapper) {
            let formSearch = wrapper.querySelector('.ais-SearchBox-form');
            if (formSearch) {
                input.classList.add("ais-SearchBox-input");
                input.setAttribute("placeholder", widgetParams.searchablePlaceholder);
                formSearch.prepend(input);
                formSearch.addEventListener("submit", function (e) {
                    let firstItem = refinementWrapper.querySelector(".ais-RefinementList-item > a");
                    if (firstItem) {
                        firstItem.click();
                    }
                    e.preventDefault();
                });
            }
            ul.classList.add("ais-RefinementList-list");
            refinementWrapper.appendChild(ul);
        }

        widgetParams.container.appendChild(refinementWrapper);
    }
}

function buildCustomSearchInteraction(renderOptions){
    const {
        items,
        isFromSearch,
        refine,
        createURL,
        searchForItems,
        widgetParams
    } = renderOptions;

    const input = widgetParams.container.querySelector('.ais-SearchBox-input');

    if (!isFromSearch && input.value) {
        input.value = '';
    }

    let currentItems = items;

    if (isFromSearch && input.value !== "" && widgetParams.searchStartWith) {
        let searchValue = input.value;
        searchValue = convertBackValue(searchValue);
        currentItems = items.filter((item) => item.value.startsWith(searchValue))
    }

    let displayType = 0;
    let dataType = 0;

    if(widgetParams.refinement && widgetParams.refinement.displayType){
        displayType = parseInt(widgetParams.refinement.displayType);
        dataType = parseInt(widgetParams.refinement.dataType);
    }

    widgetParams.container.querySelector('ul').innerHTML = currentItems.map(
        item => getTemplateRefinementListItem(item,createURL(item.value),displayType,dataType)
    ).join('');

    if (currentItems.length === 0) {
        widgetParams.container.querySelector('ul').innerHTML = "<li>"+GLOBALS.tc.noResult+"</li>";
    }

    let inputSearch = widgetParams.container.querySelector('.ais-SearchBox-input');
    let formSearch = widgetParams.container.querySelector('.ais-SearchBox-form');

    for(let element of widgetParams.container.querySelectorAll('a')){
        element.addEventListener('click', event => {
            event.preventDefault();
            refine(event.currentTarget.dataset.value);
        });
    }

    for(let element of widgetParams.container.querySelectorAll('.ais-RefinementList-checkbox')){
        element.addEventListener('click', event => {
            event.preventDefault();
            refine(element.value);
        });
    }

    if (formSearch){
        formSearch.addEventListener('submit', event => {
            event.stopImmediatePropagation();
            event.preventDefault();
            event.stopPropagation();
            let list = inputSearch.closest('.ais-RefinementList');
            let firstItem = list.querySelector(".ais-RefinementList-checkbox,.ais-RefinementList-item > a");
            if(firstItem){
                if(firstItem.tagName === "INPUT"){
                    refine(firstItem.value);
                }
                if(!formSearch.closest(".headerExpertSearch")){
                    if(firstItem.tagName === "A"){
                        firstItem.click();
                    }
                }
            }
        });
    }

    if(inputSearch) {
        inputSearch.addEventListener('keyup', event => {
            event.stopImmediatePropagation();
            event.preventDefault();
            event.stopPropagation();
            searchForItems(event.currentTarget.value);
        });

        let inputReset = widgetParams.container.querySelector('.ais-SearchBox-reset');

        if(inputReset){
            inputReset.addEventListener("click", function (event) {
                event.stopImmediatePropagation();
                event.preventDefault();
                event.stopPropagation();
                if(inputSearch){
                    inputSearch.focus();
                }
                inputReset.hidden = true;
                searchForItems("");
            });
        }
    }
}

function updateAvailableFilters(activeFilterListItems, filterListItems, filterOutput, filterSearch) {
    let activeFilterItemArray = [];
    for (let activeFilterItem of activeFilterListItems) {
        activeFilterItemArray.push(activeFilterItem.querySelector('.ais-RefinementList-labelText').innerText);
    }
    let newFilterOutputText = activeFilterItemArray.join(', ');
    if (activeFilterItemArray.length > 0) {
        if (activeFilterItemArray.length > 1) {
            newFilterOutputText = newFilterOutputText + ' (' + filterListItems.length + ')';
        }
        filterOutput.innerText = filterSearch.placeholder + ': ' + newFilterOutputText;
    } else {
        filterOutput.innerText = filterSearch.placeholder + ' (' + filterListItems.length + ')';
    }
}

function getNewSearchHref() {
    const url = new URL(location.href);
    return  customizedUrl + '/search/' + String(url.search);
}

function openFilter(filterWrapper) {
    if (filterWrapper) {
        let filterSearch = filterWrapper.querySelector('.ais-SearchBox-input');
        filterWrapper.classList.add('is-open');
        if (filterSearch) {
            filterSearch.disabled = false;
            filterSearch.focus();
            filterSearch.select();
            window.addEventListener("keydown", arrowKeysHandler, false);
        }
    }
}

function closeFilter(filterWrapper) {
    filterWrapper.classList.remove('is-open');
    deactivateFilterListElement(filterWrapper);
    window.removeEventListener("keydown", arrowKeysHandler);
}

function closeAllFilters(options) {
    for (let filterWrapper of options.expertSearchBarFilterWrapper.querySelectorAll('.filterWrapper.is-open')) {
        closeFilter(filterWrapper);
    }
}

function openNextFilterAfterSelected() {
    let lastSelected = false;
    let index = 0;
    let lastSelectedIndex = 0;
    let filterWrappers = document.querySelectorAll('.headerExpertSearch .filterWrapper:not(.disabled)');
    if (filterWrappers) {
        for (let filterWrapper of filterWrappers) {
            let hasSelected = filterWrapper.querySelector('.ais-RefinementList-item--selected');

            if (hasSelected) {
                lastSelectedIndex = index;
                lastSelected = filterWrapper;
                closeFilter(filterWrapper);
            }
            index++;
        }
        if (lastSelected) {
            openFilter(filterWrappers[lastSelectedIndex + 1]);
        }
    }
}

function openExpertSearchLists(options) {
    for (let filterWrapper of options.expertSearchBarFilterWrapper.querySelectorAll('.filterWrapper')) {
        let filterOutput = filterWrapper.querySelector('.filterOutput');
        let filterSearch = filterWrapper.querySelector('.ais-SearchBox-input');
        let filterList = filterWrapper.querySelector('.ais-RefinementList-list');

        if (filterList) {
            let filterListItems = filterList.querySelectorAll('.ais-RefinementList-item');
            let activeFilterListItems = filterList.querySelectorAll('.ais-RefinementList-item.ais-RefinementList-item--selected');

            updateAvailableFilters(activeFilterListItems, filterListItems, filterOutput, filterSearch);

            for (let filterListItem of filterListItems) {
                filterListItem.addEventListener('click', () => {
                    filterWrapper.classList.remove('is-open');
                });
            }

            if (filterListItems.length === 0) {
                filterWrapper.classList.add('disabled');
            } else {
                filterWrapper.classList.remove('disabled');
            }
        } else {
            filterWrapper.classList.add('disabled');
        }

        filterOutput.addEventListener('click', (e) => {
            let allFilterWrappers = filterOutput.closest('.filterBox__filter').querySelectorAll('.filterWrapper');
            for (let filterWrapperToClose of allFilterWrappers) {
                filterWrapperToClose.classList.remove('is-open');
            }
            e.stopPropagation();
            e.preventDefault();
            openFilter(filterWrapper);
            clickedFirst = true;
        });
    }
}

function loadAlgoliaExpertSearchFilter(options,callback = function (){}){

    const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);

    const algoliaInstantExpertSearch = instantsearch({
        indexName: algoliaIndexNameExpert,
        searchClient,
        routing: true,
        future: {
            preserveSharedStateOnUnmount: true,
        }
    });
    const customExpertSearchFilter = connectRefinementList((renderOptions, isFirstRender) => {
        if (isFirstRender) {
            buildExpertSearchFilter(renderOptions);
        }
        buildCustomSearchInteraction(renderOptions);
    });

    let filters = "";

    const customerCountryCode = getCustomerCountryCode();
    if(customerCountryCode){
        let blockedCountryFilter = getBlockedCountryFilter(customerCountryCode);

        if(blockedCountryFilter !== false){
            filters = `${blockedCountryFilter}: 0`;
        }
    }

    function loadAlgoliaExpertWidgetForView(){
        const algoliaHitTemplate = getCurrentAlgoliaTemplate();
        const customPagination = getCustomPagination();
        const customHitsPerPage = getCustomHitsPerPage();
        algoliaInstantExpertSearch.addWidgets([
            configure({
                maxValuesPerFacet: 1000,
                filters: filters
            }),
            hits({
                container: options.hitsWrapper,
                templates: {
                    empty(results, {html}) {
                        for(let item of document.querySelectorAll(".itemlist__header, .itemlist__footer, .itemAnnotation")){
                            item.style.display = "none";
                        }
                        let emptyText = options.tc.noResults;
                        return html`${emptyText}`;
                    },
                    item: algoliaHitTemplate
                }
            }),
            clearRefinements({
                container: document.getElementById(options.expertSearchClearRefinementId),
                templates: {
                    resetLabel({hasRefinements}, {html}) {
                        return html`<i class="icon icon-trash ${hasRefinements ? '' : 'disabled'}"></i>`;
                    },
                },
            }),
            customHitsPerPage({
                container: options.hitsPerItems,
                items: [
                    {label: '15 ' + options.tc.itemsPerPage, value: 15, default: true},
                    {label: '50 ' + options.tc.itemsPerPage, value: 50},
                    {label: '100 ' + options.tc.itemsPerPage, value: 100},
                ],
            }),
            customPagination({
                container: options.pagination
            }),
            customExpertSearchFilter({
                container: document.querySelector('#diameter-list'),
                attribute: 'DURCHMESSER',
                searchable: true,
                operator: 'and',
                limit: options.limit,
                showMore: true,
                showMoreLimit: 1000,
                searchablePlaceholder: GLOBALS.tc.diameter,
                searchableEscapeFacetValues: true,
                sortBy: sortNumerically,
                searchStartWith: true
            }),
            customExpertSearchFilter({
                container: document.querySelector('#DIN-list'),
                attribute: 'NORMNO',
                searchable: true,
                operator: 'and',
                limit: options.limit,
                showMore: true,
                showMoreLimit: 1000,
                searchablePlaceholder: 'DIN-ISO-ART',
                sortBy: sortNumerically,
                searchStartWith: true
            }),
            customExpertSearchFilter({
                container: document.querySelector('#length-list'),
                attribute: 'GESAMTLAENGE',
                searchable: true,
                operator: 'and',
                limit: options.limit,
                showMore: true,
                showMoreLimit: 1000,
                searchablePlaceholder: GLOBALS.tc.length,
                sortBy: sortNumerically,
                searchStartWith: true
            }),
            customExpertSearchFilter({
                container: document.querySelector('#material-list'),
                attribute: 'WERKSTOFFE',
                operator: 'or',
                limit: options.limit,
                showMore: true,
                showMoreLimit: 1000,
                searchable: true,
                searchablePlaceholder: GLOBALS.tc.material,
                refinement: {
                    displayType:4,
                    dataType: 4
                },
                sortBy: ['name:asc']
            }),
            customExpertSearchFilter({
                container: document.querySelector('#outer-diameter-list'),
                attribute: 'AUSSENDURCHMESSER',
                searchable: true,
                operator: 'and',
                limit: options.limit,
                showMore: true,
                showMoreLimit: 1000,
                searchablePlaceholder: GLOBALS.tc.outsideDiameter,
                sortBy: sortNumerically,
                searchStartWith: true
            }),
            customExpertSearchFilter({
                container: document.querySelector('#thread-type-list'),
                attribute: 'GEWINDEART',
                operator: 'or',
                limit: options.limit,
                showMore: true,
                showMoreLimit: 1000,
                searchable: true,
                searchablePlaceholder: GLOBALS.tc.threadType,
                refinement: {
                    displayType:4
                },
                sortBy: ['name:asc']
            }),
            customExpertSearchFilter({
                container: document.querySelector('#inner-diameter-list'),
                attribute: 'INNENDURCHMESSER',
                searchable: true,
                operator: 'and',
                limit: options.limit,
                showMore: true,
                showMoreLimit: 1000,
                searchablePlaceholder: GLOBALS.tc.insideDiameter,
                sortBy: sortNumerically,
                searchStartWith: true
            })
        ]);
    }

    function loadAlgoliaExpertWidgetForSearch(){
        algoliaInstantExpertSearch.addWidgets([
            configure({
                query: '*',
                filters: filters,
                maxValuesPerFacet: 1000,
            }),
            clearRefinements({
                container: document.getElementById(options.expertSearchClearRefinementId),
                templates: {
                    resetLabel({hasRefinements}, {html}) {
                        return html`<i class="icon icon-trash ${hasRefinements ? '' : 'disabled'}"></i>`;
                    },
                },
            }),
            customExpertSearchFilter({
                container: document.querySelector('#diameter-list'),
                attribute: 'DURCHMESSER',
                searchable: true,
                operator: 'and',
                limit: options.limit,
                showMore: true,
                showMoreLimit: 1000,
                searchablePlaceholder: GLOBALS.tc.diameter,
                searchableEscapeFacetValues: true,
                sortBy: sortNumerically,
                searchStartWith: true
            }),
            customExpertSearchFilter({
                container: document.querySelector('#DIN-list'),
                attribute: 'NORMNO',
                searchable: true,
                operator: 'and',
                limit: options.limit,
                showMore: true,
                showMoreLimit: 1000,
                searchablePlaceholder: 'DIN-ISO-ART',
                sortBy: sortNumerically,
                searchStartWith: true
            }),
            customExpertSearchFilter({
                container: document.querySelector('#length-list'),
                attribute: 'GESAMTLAENGE',
                searchable: true,
                operator: 'and',
                limit: options.limit,
                showMore: true,
                showMoreLimit: 1000,
                searchablePlaceholder: GLOBALS.tc.length,
                sortBy: sortNumerically,
                searchStartWith: true
            }),
            customExpertSearchFilter({
                container: document.querySelector('#material-list'),
                attribute: 'WERKSTOFFE',
                operator: 'or',
                limit: options.limit,
                showMore: true,
                showMoreLimit: 1000,
                searchable: true,
                searchablePlaceholder: GLOBALS.tc.material,
                refinement: {
                    displayType:4,
                    dataType: 4
                },
                sortBy: ['name:asc']
            }),
            customExpertSearchFilter({
                container: document.querySelector('#outer-diameter-list'),
                attribute: 'AUSSENDURCHMESSER',
                searchable: true,
                operator: 'and',
                limit: options.limit,
                showMore: true,
                showMoreLimit: 1000,
                searchablePlaceholder: GLOBALS.tc.outsideDiameter,
                sortBy: sortNumerically,
                searchStartWith: true
            }),
            customExpertSearchFilter({
                container: document.querySelector('#thread-type-list'),
                attribute: 'GEWINDEART',
                operator: 'or',
                limit: options.limit,
                showMore: true,
                showMoreLimit: 1000,
                searchable: true,
                searchablePlaceholder: GLOBALS.tc.threadType,
                refinement: {
                    displayType:4
                },
                sortBy: ['name:asc']
            }),
            customExpertSearchFilter({
                container: document.querySelector('#inner-diameter-list'),
                attribute: 'INNENDURCHMESSER',
                searchable: true,
                operator: 'and',
                limit: options.limit,
                showMore: true,
                showMoreLimit: 1000,
                searchablePlaceholder: GLOBALS.tc.insideDiameter,
                sortBy: sortNumerically,
                searchStartWith: true
            })
        ]);
    }

    if(options.isExpertSearchView){
        loadAlgoliaExpertWidgetForView();
    }else{
        loadAlgoliaExpertWidgetForSearch();
    }

    algoliaInstantExpertSearch.start();

    algoliaInstantExpertSearch.on('render', (e) => {
        if (algoliaInstantExpertSearch.status === "idle") {

            if(options.isExpertSearchView){
                showSearchView();

                if(firstLoad){
                    firstLoad = false;
                    options.instantSearchWrapper.style.display = "";
                }

                updateHitValues(options);

                if (!checkActiveFilter(options)) {
                    showInactiveFilterMessage(options);
                    hideListViewSwitch(options);
                } else {
                    hideInactiveFilterMessage(options);
                    showListViewSwitch(options);
                }
            }
            openExpertSearchLists(options);

            if (clickedFirst) {
                openNextFilterAfterSelected(options);
            } else {
                handleArrowKeysPress();
            }

            handleTabPress();

            callback();
        }
    });

    handleClickOutside(options);
    handleEnterPressFilterCheckbox();
}

function showExpertSearchBar(options,event = false){
    const expertSearchBar = options.expertSearchBar;
    let target = false;

    if(event){
        target = event.target;
    }

    if(expertSearchBar){
        addLoadingClass(expertSearchBar);
        if(target){
            addLoadingClass(target);
        }
        XhrService.generalAjaxCall('getProfiSearchFilterRender', {"profi_search": true}, 'POST', 'json').then(response => {
            options.expertSearchBarFilterWrapper.innerHTML = response.filter;
            removeLoadingClass(expertSearchBar);
            if(target){
                removeLoadingClass(target);
            }
            loadAlgoliaExpertSearchFilter(options,function (){
                expertSearchBar.classList.add("is-open");
                document.cookie='expertSearchVisible=true; max-age=604800; path=/';
                handleClickSubmitButton(options);
            });
        });
    }
}

function hideExpertSearchBar(options,event = false){
    const expertSearchBar = options.expertSearchBar;
    if(expertSearchBar){
        expertSearchBar.classList.remove("is-open");
        options.expertSearchBarFilterWrapper.innerHTML = "";
        document.cookie='expertSearchVisible=; max-age=604800; path=/';
    }
}

function showErrorMessage(options){
    let filterErrorMessage = options.expertSearchBar.querySelector('.filterErrorMessage');
    if (filterErrorMessage) {
        slideDown(filterErrorMessage);
        setTimeout(function (){
            slideUp(filterErrorMessage);
        },5000);
    }
}

function hideErrorMessage(options){
    let filterErrorMessage = options.expertSearchBar.querySelector('.filterErrorMessage');
    if (filterErrorMessage) {
        slideUp(filterErrorMessage);
    }
}

function showInactiveFilterMessage(options){
    const inactiveFilterInfo = options.inactiveFilterInfo;
    if (inactiveFilterInfo) {
        options.instantSearchWrapper.style.display = "none";
        inactiveFilterInfo.style.display = "";
    }
}

function hideInactiveFilterMessage(options){
    const inactiveFilterInfo = options.inactiveFilterInfo;
    if (inactiveFilterInfo) {
        options.instantSearchWrapper.style.display = "";
        inactiveFilterInfo.style.display = "none";
    }
}

export function initAlgoliaExpertSearch(isExpertSearchView = false){
    const options = {
        "expertSearchBar": document.getElementById("js-expertSearchBar"),
        "expertSearchBarFilterWrapper": document.getElementById("js-expertSearchBarFilterWrapper"),
        "expertSearchOpenerClass": ".js-expertSearchOpener",
        "expertSearchCloserClass": ".js-expertSearchCloser",
        "expertSearchBarSubmitButtonId": "transferSearchParameters",
        "expertSearchClearRefinementId": "js-expertSearchClearAllRefinements",
        "instantSearchWrapper": document.getElementById("ais-InstantSearch"),
        "hitsWrapper": document.getElementById("algoliaHitsList"),
        "clearRefinement": document.getElementById("algoliaClearAllRefinements"),
        "currentRefinements": document.getElementById("algoliaCurrentRefinements"),
        "numberOfHits": document.getElementById("algoliaNumberOfHits"),
        "hitsPerItems": document.getElementById("algoliaCountItemsPerPageSwitch"),
        "pagination": document.getElementById("algoliaPagination"),
        "hitsViewSwitch": document.getElementById("algoliaHitsViewSwitch"),
        "hitsView": document.getElementById("algoliaHitsView"),
        "categoryView": document.getElementById("algoliaCategoryView"),
        "listView": document.getElementById("algoliaListView"),
        "inactiveFilterInfo": document.getElementById("inactiveFilterInfo"),
        "limit": 990,
        "tc": {
            "noResults": GLOBALS.tc.noResult,
            "foundItems": GLOBALS.tc.foundItems,
            "removeFilter": GLOBALS.tc.removeFilter,
            "itemsPerPage": GLOBALS.tc.itemsPerPage
        }
    };

    try {
        options.isExpertSearchView = false;
        if(isExpertSearchView){
            options.expertSearchBar.classList.add("is-open");
            options.isExpertSearchView = true;
            document.body.classList.add("isExpertSearch");

            buildItemlistViewSwitch(options);
        }

        if(options.expertSearchBar.classList.contains("is-open")){
            showExpertSearchBar(options,false);
        }

        document.addEventListener("click", function (e) {
            let target = e.target;
            let opener = target.closest(options.expertSearchOpenerClass);
            let closer = target.closest(options.expertSearchCloserClass);

            if(opener){
                const expertSearchBar = options.expertSearchBar;
                if(expertSearchBar){
                    if(expertSearchBar.classList.contains("is-open")){
                        hideExpertSearchBar(options,e);
                    }else{
                        showExpertSearchBar(options,e);
                    }
                }
            }

            if(closer){
                hideExpertSearchBar(options,e);
            }
        });
    } catch(err){
        console.error(err);
    }
}
